"use client";

import React, { useEffect } from "react";
import {
  loadGtagScript,
  loadYMScript,
  sendSafeGtagEvent,
  sendSafeYMEvent,
  sleep,
} from "@/shared/lib/helpers";

enum AnalyticsServices {
  Yandex = "ym",
  Google = "gtag",
}

type AnalyticsContextProps = {
  sendSafeYMEvent: typeof sendSafeYMEvent;
  sendSafeGtagEvent: typeof sendSafeGtagEvent;
};

const AnalyticsContext = React.createContext<AnalyticsContextProps>({
  sendSafeYMEvent,
  sendSafeGtagEvent,
});

function AnalyticsContextProvider<T extends {}>(props: T) {
  const [unsentEventsTuple, setUnsentEventsTuple] = React.useState<
    { [k in AnalyticsServices]?: unknown[][] } | null
  >(null);
  const [isAnalyticsLoaded, setIsAnalyticsLoaded] = React.useState(false);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      process.env.NEXT_PUBLIC_RUNTIME_ENV !== "development"
    ) {
      const loadAnalyticScripts = async () => {
        loadYMScript();
        loadGtagScript();
        setIsAnalyticsLoaded(true);
        window.removeEventListener("load", loadAnalyticScripts);
      };

      if (document.readyState === "complete") {
        loadAnalyticScripts();
      } else {
        window.addEventListener("load", loadAnalyticScripts);

        return () => {
          window.removeEventListener("load", loadAnalyticScripts);
        };
      }
    }
  }, []);

  React.useEffect(
    function sendAllUnsentEvents() {
      if (isAnalyticsLoaded && unsentEventsTuple) {
        const handler = async () => {
          await sleep(2 * 1000);
          Object.entries(unsentEventsTuple).forEach(
            ([serviceName, eventsList]) => {
              switch (serviceName as unknown as AnalyticsServices) {
                case AnalyticsServices.Google:
                  eventsList.forEach((args) =>
                    sendSafeGtagEvent(
                      ...(args as Parameters<typeof sendSafeGtagEvent>),
                    ),
                  );
                  break;
                case AnalyticsServices.Yandex:
                  eventsList.forEach((args) =>
                    sendSafeYMEvent(
                      ...(args as Parameters<typeof sendSafeYMEvent>),
                    ),
                  );
                  break;
                default:
                  break;
              }
            },
          );

          setUnsentEventsTuple(null);
        };
        handler();
      }
    },
    [isAnalyticsLoaded, unsentEventsTuple],
  );

  function updateUnsentEventsList<A extends unknown[]>(
    args: A,
    type: AnalyticsServices,
  ) {
    setUnsentEventsTuple((prev) => ({
      ...prev,
      [type]: [...(prev?.[type] ?? []), args],
    }));
  }

  const analyticsSendingFunctions = React.useMemo(() => {
    return {
      sendSafeYMEvent: (...args: Parameters<typeof sendSafeYMEvent>) =>
        updateUnsentEventsList(args, AnalyticsServices.Yandex),

      sendSafeGtagEvent: (...args: Parameters<typeof sendSafeGtagEvent>) =>
        updateUnsentEventsList(args, AnalyticsServices.Google),
    };
  }, []);

  return (
    <AnalyticsContext.Provider value={analyticsSendingFunctions} {...props} />
  );
}

function useAnalyticsContext() {
  return React.useContext(AnalyticsContext);
}

export { AnalyticsContextProvider, AnalyticsContext, useAnalyticsContext };
