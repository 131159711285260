import styled, { css } from "styled-components";

export const Wrapper = styled.div<{
  $size: number;
  $position?: "absolute" | "relative";
  $placement?: "center";
}>`
  display: flex;
  position: ${(props) => props.$position ?? "unset"};

  ${(props) => {
    switch (props.$placement) {
      case "center":
        return css`
          left: calc(50% - ${props.$size}px / 2);
          top: calc(50% - ${props.$size}px / 2);
          align-self: center;
          vertical-align: middle;
          margin: 0 auto;
        `;

      default:
        return;
    }
  }}

  img {
    animation: opacity 2s ease-in-out infinite;
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
