import React from "react";
import { internalizationConfig } from "@/shared/lib/configs";

function Link({
  children,
  ...props
}: Parameters<typeof internalizationConfig.Link>["0"] & {
  children: React.ReactNode;
}) {
  return (
    <internalizationConfig.Link {...props}>
      {children}
    </internalizationConfig.Link>
  );
}

export default Link;
