import redirect from './redirect';
import detectLocale from './detectLocale';
import formatTelegramLink from './formatTelegramLink';
import getCurrentWeekRange from './getCurrentWeekRange';
import getLocalePrefix from './getLocalePrefix';
import buildMetadataConfig from './buildMetadataConfig';
import isClient from './isClient';
import setCookie from './setCookie';
import sleep from './sleep';
import { loadGtagScript, loadYMScript, sendSafeGtagEvent, sendSafeYMEvent } from './analytics';
import { getDirValueByLocale } from './getDirValueByLocale';
import buildTelegramLink from './buildTelegramLink';
import { lockScroll, unLockScroll } from './scrollLocker';
import { buildFullURL } from './buildFullURL';
import { buildVideoSchema } from './buildVideoSchema';
import { localStorageService } from './localStorageService';

export {
  redirect,
  loadGtagScript,
  loadYMScript,
  sendSafeGtagEvent,
  sendSafeYMEvent,
  isClient,
  setCookie,
  getCurrentWeekRange,
  getLocalePrefix,
  detectLocale,
  formatTelegramLink,
  buildMetadataConfig,
  sleep,
  getDirValueByLocale,
  buildTelegramLink,
  lockScroll,
  unLockScroll,
  buildFullURL,
  buildVideoSchema,
  localStorageService,
};
