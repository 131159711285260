'use client';

import React from 'react';
import Image from 'next/image';
import logo from '@/public/staticfiles/logo/logo_short_white.svg?url';
import * as SC from './styled';

function Loader({
  size = 52,
  placement = 'center',
  position = 'relative',
}: {
  size?: number;
  position?: 'absolute' | 'relative';
  placement?: 'center';
}) {
  return (
    <SC.Wrapper $size={size} $placement={placement} $position={position}>
      <Image height={size} src={logo} alt="" />
    </SC.Wrapper>
  );
}

export default Loader;
