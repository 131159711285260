import styled from 'styled-components';
import gradient from '@/public/staticfiles/common/gradient.png';

export const Root = styled.div`
  background: url(${gradient.src}) center top no-repeat;
  background-size: contain;
  width: 100%;
  height: 50vw;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: -1;
`;
