'use client';

import React from 'react';
import type { DeviceInfoType } from '../lib/types';
import { DEFAULT_DEVICE_INFO } from '../lib/constants';

type DeviceInfoContextProps = DeviceInfoType;

const DeviceInfoContext = React.createContext<DeviceInfoContextProps>(DEFAULT_DEVICE_INFO);

function DeviceInfoContextProvider<T extends { initValue: DeviceInfoType }>({ initValue, ...props }: T) {
  return <DeviceInfoContext.Provider value={initValue} {...props} />;
}

function useDeviceInfoContext() {
  return React.useContext<DeviceInfoContextProps>(DeviceInfoContext);
}

export { DeviceInfoContextProvider, useDeviceInfoContext };
