import styled, { css } from "styled-components";

export const Wrapper = styled.button<{
  $fullWidth?: boolean;
  $variant?: "outlined" | "secondary" | "primary";
}>`
  position: relative;
  z-index: 5;
  overflow: hidden;
  min-width: 160px;
  width: fit-content;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 22px;
  background: linear-gradient(85.66deg, #af26ed 30.77%, #1d97f3 92.01%);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-decoration: none;

  &:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
    pointer-events: none;
  }

  ${(props) =>
    props.$fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) => {
    switch (props.$variant) {
      case "secondary":
        return css`
          background: rgba(255, 255, 255, 0.1);
          border: none;
          color: white;
        `;
      case "outlined":
        return css`
          background: transparent;
          border: 1px solid
            var(--white-transparent-w-20, rgba(255, 255, 255, 0.2));
          color: white;

          &:hover {
            border-color: transparent;
          }
        `;
      default:
        return css``;
    }
  }}
`;
