const howToSchema = {
  "@context": "https://schema.org",
  "@type": "HowTo",
  name: "Nicegram Chat Unblock Guide",
  step: [
    {
      "@type": "HowToStep",
      name: "Login using “Login with Telegram” widget",
      text: "Login using “Login with Telegram” widget",
      itemListElement: [
        {
          "@type": "HowToDirection",
          text: "The Nicegram website can’t access your private data like your phone number or your messages.",
        },
      ],
    },
    {
      "@type": "HowToStep",
      name: "Toggle the switches to change your settings",
      text: "Toggle the switches to change your settings",
    },
    {
      "@type": "HowToStep",
      name: "Tap Save",
      text: "Tap Save",
    },
    {
      "@type": "HowToStep",
      name: "Restart Nicegram",
      itemListElement: [
        {
          "@type": "HowToDirection",
          text: "(kill and reopen the app)",
        },
      ],
    },
  ],
};

const videoObjectSchemaBase = {
  "@context": "https://schema.org",
  "@type": "VideoObject",
  name: "Nicegram Chat Unblock Guide",
  description: "Unlock access to locked Telegram channels",
  uploadDate: "2022-09-22T08:00:00+08:00",
};

export { videoObjectSchemaBase, howToSchema };
