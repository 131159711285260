import isClient from "./isClient";

export const lockScroll = () => {
  if (isClient()) {
    const rootEl = document.querySelector(":root") as HTMLElement;

    rootEl.style.setProperty(
      "--scroll-height",
      `${document.documentElement.scrollHeight}px`
    );
    rootEl.style.setProperty(
      "--scroll-top",
      `${document.documentElement.scrollTop}px`
    );

    document.documentElement.dataset.scrollLock = "true";
  }
};

export const unLockScroll = () => {
  if (isClient()) {
    delete document.documentElement.dataset.scrollLock;
  }
};
