import styled, { css } from "styled-components";

export const Wrapper = styled.label<{ $checked: boolean }>`
  display: block;
  width: 52px;
  height: 30px;
  flex: 0 0 52px;
  border-radius: 100vmax;
  background: rgba(120, 120, 128, 0.32);
  transition: 0.4s var(--easing-func);
  position: relative;
  cursor: pointer;

  &:has(input:disabled) {
    filter: grayscale(1);
    pointer-events: none;
    cursor: not-allowed;
  }

  > div {
    left: 2px;
    top: 2px;
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 100vmax;
    background: #ffffff;
    transition: 0.3s var(--easing-func);
  }

  input {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
  }

  ${(props) =>
    props.$checked &&
    css`
      background: #30d158;

      > div {
        left: calc(100% - 28px);
      }
    `}
`;
