export const LANGS_LIST = [
  "zh-TW",
  "zh-CN",
  "vi",
  "ru",
  "tr",
  "pt",
  "ko",
  "it",
  "fr",
  "es",
  "en",
  "de",
  "ar",
] as const;

export const DEFAULT_LANG: (typeof LANGS_LIST)[number] = "en";
