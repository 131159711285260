import { createLocalizedPathnamesNavigation } from 'next-intl/navigation';
import { internalization } from '@/shared/lib/constants';
import { LocalePrefix } from 'next-intl/dist/types/src/shared/types';

export const pathnames = {
  '/': '/',
  '/settings': '/settings',
};

// Use the default: `always`
export const localePrefix: LocalePrefix = 'as-needed';

export const { Link, redirect, usePathname, useRouter } = createLocalizedPathnamesNavigation({
  locales: internalization.LANGS_LIST,
  pathnames,
  localePrefix,
});
