export { default as LockIcon } from "./lock.svg";
export { default as PlayButtonIcon } from "./play-tutor.svg";
export { default as PlayMobIcon } from "./play-mob.svg";
export { default as CalendarIcon } from "./calendar.svg";
export { default as KeySquareIcon } from "./key-square.svg";
export { default as DangerTriangleIcon } from "./danger-triangle.svg";
export { default as AppstoreIcon } from "./appstore.svg";
export { default as GplayIcon } from "./gplay.svg";
export { default as LogoutIcon } from "./logout.svg";
export { default as PlaneIcon } from "./paper-plane.svg";
