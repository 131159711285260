"use client";

import React from "react";
import * as SC from "./styled";

function Index(params: Parameters<typeof SC.Wrapper>[0]) {
  return <SC.Wrapper {...params} />;
}

export default Index;
