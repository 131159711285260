const DEFAULT_DEVICE_INFO = {
  isIOS: false,
  isAndroid: false,
  isDesktop: true,
  isMobile: false,
  type: 'unknown',
  isTelegramBot: false,
};

export { DEFAULT_DEVICE_INFO };
