import React from "react";
import { lockScroll, unLockScroll } from "../helpers";

const useScrollLock = (lock: boolean): void => {
  React.useEffect(() => {
    if (lock) {
      lockScroll();
    } else {
      unLockScroll();
    }

    return () => unLockScroll();
  }, [lock]);
};

export default useScrollLock;
