import { videoObjectSchemaBase } from "@/shared/lib/constants";

function buildVideoSchema(videoUrl: string) {
  return {
    ...videoObjectSchemaBase,
    thumbnailUrl: `https://img.youtube.com/vi/${videoUrl}/default.jpg`,
    contentUrl: videoUrl,
    embedUrl: videoUrl,
  };
}

export { buildVideoSchema };
