"use client";

import React from "react";
import type { BaseInputProps, InputsTypes } from "../types";
import * as SC from "./styled";

const TYPE: InputsTypes = "checkbox";

type ToggleInputProps = BaseInputProps &
  Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  >;

function ToggleInput({
  checked = false,
  name,
  onChange,
  ...props
}: ToggleInputProps) {
  const updatedOnChange = React.useCallback<
    NonNullable<React.InputHTMLAttributes<HTMLInputElement>["onChange"]>
  >(
    (e) => {
      onChange({
        name,
        value: e.target.value,
        type: TYPE,
        checked: e.target.checked,
      });
    },
    [name, onChange],
  );

  return (
    <SC.Wrapper $checked={checked}>
      <div />
      <input
        {...props}
        checked={checked}
        onChange={updatedOnChange}
        type="checkbox"
      />
    </SC.Wrapper>
  );
}

export default ToggleInput;
