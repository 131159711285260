import { internalization } from "../constants";

export default function detectLocale(pathname: string): string {
  // Split the pathname on '/'
  const segments = pathname.split("/");

  // Check if the first segment is a valid locale
  if (
    segments.length > 1 &&
    internalization.LANGS_LIST.includes(
      segments[1] as (typeof internalization.LANGS_LIST)[number],
    )
  ) {
    return segments[1]; // Return the locale
  }

  return internalization.DEFAULT_LANG; // No locale found, return null or default locale
}
