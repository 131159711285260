'use client';

enum LocalStorageNames {
  TOKEN = 'userAccessToken',
}

const localStorageService = {
  [LocalStorageNames.TOKEN]: {
    set: (token: string) => {
      localStorage.setItem(LocalStorageNames.TOKEN, token);
    },
    get: (): string | null => localStorage.getItem(LocalStorageNames.TOKEN) || null,
    remove: () => {
      localStorage.removeItem(LocalStorageNames.TOKEN);
    },
  },
};

export { localStorageService };
