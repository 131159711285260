import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ComponentProps,
  ForwardedRef,
} from "react";
import Link from "next/link";
import { Wrapper } from "./styled";

export type ButtonProps<As extends string | undefined = "button"> =
  (As extends "a"
    ? AnchorHTMLAttributes<HTMLAnchorElement>
    : ButtonHTMLAttributes<HTMLButtonElement>) & {
    label?: React.ReactNode;
    fullWidth?: boolean;
    variant?: "outlined" | "secondary" | "primary";
    as?: As;
  } & (As extends "a" ? ComponentProps<typeof Link> : {});

function ButtonInner<T extends string | undefined>(
  {
    label,
    fullWidth = false,
    variant,
    className,
    children,
    as,
    ...props
  }: ButtonProps<T>,
  ref: ForwardedRef<T extends "a" ? HTMLAnchorElement : HTMLButtonElement>,
) {
  return (
    <Wrapper
      ref={ref}
      as={as === "a" ? Link : as}
      $variant={variant}
      $fullWidth={fullWidth}
      className={`btn ${className ?? ""}`}
      {...props}
    >
      <>
        {label}
        {children}
      </>
    </Wrapper>
  );
}

const Button = React.forwardRef(ButtonInner) as <
  T extends string | undefined = string,
>(
  props: ButtonProps<T> & {
    ref?: ForwardedRef<T extends "a" ? HTMLAnchorElement : HTMLButtonElement>;
  },
) => ReturnType<typeof ButtonInner>;

export default Button;
