import { Metadata } from 'next/types';
import { common, internalization } from '@/shared/lib/constants';
import socialMediaLogo from '@/public/staticfiles/logo/social_media_logo.png';

import { buildFullURL } from './buildFullURL';

function buildMetadataConfig({
  language,
  supportedLangs = internalization.LANGS_LIST,
  pathname,
  title,
  description,
}: {
  title: string;
  description: string;
  language: string;
  supportedLangs?: typeof internalization.LANGS_LIST;
  pathname: string;
}): Metadata {
  const alternateLinksLanguagesDict = supportedLangs.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: buildFullURL({
        baseUrl: common.BASE_URL,
        language: curr,
        path: pathname,
      }),
    }),
    {} as Record<string, string>,
  );

  const currentURL = buildFullURL({
    path: pathname,
    baseUrl: common.BASE_URL,
    language,
  });

  const metaImage = {
    url: `${common.BASE_URL}/staticfiles/logo/social_media_logo.png`,
    width: 1024,
    height: 1024,
  };

  return {
    title,
    description,
    alternates: {
      canonical: currentURL,
      ...(supportedLangs.length > 1 && {
        languages: {
          ...alternateLinksLanguagesDict,
          'x-default': buildFullURL({
            path: pathname,
            baseUrl: common.BASE_URL,
            language: 'en',
          }),
        },
      }),
    },
    twitter: {
      card: 'summary',
      title,
      description,
      images: metaImage,
    },
    openGraph: {
      locale: language,
      type: 'website',
      url: currentURL,
      siteName: 'Nicegram',
      images: [metaImage],
      title,
      description,
    },
  };
}

export default buildMetadataConfig;
